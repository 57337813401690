* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	/* background-color: #282c34; */
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.App {
	background-image: url("../images/starBG.jpg");
	background-size: cover;
	background-position: center;
}
.moon-bg {
	background-image: url("../images/starBG.jpg");
	background-size: cover;
	background-position: center;
}

.sun-bg {
	background-image: url("../images/sunSky.png");
	background-size: cover;
	background-position: center;
}
.moon-img {
	max-width: 400px;
}
.date-time {
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 20px;
	color: white;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
	padding: 5px;
	border-radius: 8px;
	z-index: 1;
	font-weight: 400;
}
.more-info-button {
	position: absolute;
	bottom: 15px; /* Adjust this value as needed */
	right: auto; /* Adjust this value as needed */
	left: auto;
	color: #fff;
	border: none;
	cursor: pointer;
	font-size: 20px;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	text-align: center;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.1);
}

.more-info {
	background-color: #eee;
	padding: 10px;
	border-radius: 5px;
	position: absolute;
	bottom: 40px;
	color: #333;
}

button {
	margin-top: 0px;
	padding: 5px 10px;
	border: none;
	cursor: pointer;
	font-size: 16px;
	border-radius: 5px;
}

button:hover {
	background-color: rgba(0, 0, 0, 1);
}
.menu-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	font-size: 1.4em;
}

.drawer {
	position: absolute;
	right: -200px;
	top: 0;
	width: 200px;
	height: 100%;
	background: white;
	transition: right 0.3s;
	z-index: 999999;
}

.drawer.open {
	right: 0;
}
.drawer button {
	position: absolute;
	left: 0px;
	background-color: #fff;
}
.drawer button:hover {
	background-color: none;
}
.drawer .fa-xmark {
	line-height: 40px; /* Align the icon vertically center */
	color: #000;
	position: relative;
	right: 0px;
	font-size: 1.6em;
}

.drawer ul {
	list-style: none;
	padding: 20px;
}

.drawer li {
	padding: 10px;
	cursor: pointer;
}

.sun-screen {
	height: 100vh;
	color: #fff;
	font-weight: bold;
	/* text-shadow: 1px 1px 1px #000; */
}
.sun-bottom {
	position: absolute;
	bottom: 20px;
	left: auto;
	right: auto;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	padding-top: 10px;
	padding-bottom: 10px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
